import React from 'react';
import ConvPred from '../components/sections/generales/convocatorias/convocatorias';

const componentMapping = {
  conv_pred: ConvPred,
};

const Convocatorias = () => {
  return (
    <>
    {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
    </>
  );
};

export default Convocatorias;
