import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import './main_banner.css';
import { getStaticUrl } from '../../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../../utils/getJsonFromStorage';

function MainBanner() {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchBanners = async () => {
      const jsonData = await getJsonFromStorage('banners.json'); // Fetch from storage
      if (jsonData && jsonData.banners) {
        setBanners(jsonData.banners.map(getStaticUrl)); // Convert paths
      } else {
        setError(true);
      }
      setLoading(false);
    };

    fetchBanners();
  }, []);

  if (loading) return <p>Cargando imagen...</p>;
  if (error) return <p>Error al cargar imagen.</p>;

  return (
    <Carousel>
      {banners.map((src, index) => (
        <Carousel.Item key={index}>
          <img className="d-block w-100" src={src} alt={`Slide ${index + 1}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MainBanner;
