import React, { useState, useEffect } from 'react';
import './statistics.css';
import CountUp from 'react-countup';
import { getJsonFromStorage } from '../../../../../utils/getJsonFromStorage';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner

const defaultStatistics = [
  { count: 300, description: "Mujeres en la Comunidad" },
  { count: 28, description: "Programas implementados" },
  { count: 1900, description: "Horas de capacitación" },
  { count: 5, description: "Regiones impactadas" },
  { count: 29, description: "Empresas confiaron" }
];

function Statistics() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const jsonData = await getJsonFromStorage('statisticsData.json');
      if (jsonData) {
        setData(jsonData);
      } else {
        setData(defaultStatistics); // Use fallback data
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <Spinner animation="border" role="status">
        </Spinner>
      </div>
    );
  }

  return (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <p className="st_p">+<span><CountUp start={0} end={item.count} /></span></p>
          <p>{item.description}</p>
        </li>
      ))}
    </ul>
  );
}

export default Statistics;
