import React, { useState, useEffect } from 'react';
import './convocatorias.css';
import { Link } from 'react-router-dom';
import { getStaticUrl } from '../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../utils/getJsonFromStorage';

function Convocatoria() {
  const [convocatoria, setConvocatoria] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchConvocatoria = async () => {
      const jsonData = await getJsonFromStorage('convocatorias.json'); // Fetch from storage
      if (jsonData && Object.keys(jsonData).length > 0) {
        setConvocatoria(jsonData);
      } else {
        setConvocatoria(null);
      }
      setLoading(false);
    };

    fetchConvocatoria().catch(() => setError(true));
  }, []);

  if (loading) return <p>Cargando convocatorias...</p>;
  if (error) return <p>Error al cargar convocatorias.</p>;

  return (
    <>
      {convocatoria ? (
        <div className="card cconvid">
          <div className="conv-logos">
            <img src={getStaticUrl(convocatoria.logo1)} alt="Logo 1" />
            <img src={getStaticUrl(convocatoria.logo2)} alt="Logo 2" />
          </div>
          <h2>{convocatoria.titulo}</h2>
          <h5>{convocatoria.ubicacion}</h5>
          <span>Inicio:</span>
          <p>{convocatoria.inicio}</p>
          <span>Horario:</span>
          <p>{convocatoria.horario}</p>
          <span>Sede:</span>
          <p>{convocatoria.sede}</p>
          <span>Requisitos:</span>
          <ul>
            {convocatoria.requisitos.map((req, index) => (
              <li key={index}>{req}</li>
            ))}
          </ul>
          <a href={convocatoria.link} className="btn btn-primary bl">
            Postular
          </a>
        </div>
      ) : (
        <div className="convnone">
          <h2>Convocatorias Cerradas</h2>
          <p>Actualmente no contamos con convocatorias abiertas, pero puedes registrarte en nuestras bases para recibir avisos.</p>

          <Link to="https://redmaestra.azurewebsites.net/api/formularios/agregar_comunidades/" className="btn btn-primary bl">
            Quiero estar informada
          </Link>

          <img src={getStaticUrl('convocatorias/conv.gif')} alt="convgif" className="n4" />
        </div>
      )}
    </>
  );
}

export default Convocatoria;
