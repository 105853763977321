import React from 'react';
import './prog_banner.css';
import { getStaticUrl } from '../../../../../utils/getStaticUrl';

function ProgBanner() {
  return (
    <div>
      <img src={getStaticUrl('banner/prog_banner.png')} alt="Program Banner" loading="eager" />
      <div className="prog_ban_text">
        <p><span>Conoce nuestros programas</span></p>
        <a href="/programas"><span>Ver más</span></a>
      </div>
    </div>
  );
}

export default ProgBanner;
