import './detalle.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { getJsonFromStorage } from '../../../../utils/getJsonFromStorage';

function NovItem() {
  const { itemId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadItemData = async () => {
      setIsLoading(true);
      try {
        const fileName = location.pathname.includes('/noticias') ? 'noticias.json' : 'testimonios.json';
        const data = await getJsonFromStorage(fileName);
        if (!data) throw new Error('Failed to fetch data');

        const selectedItem = data.find(item => item.id.toString() === itemId);
        setItemData(selectedItem || null);
      } catch (error) {
        console.error('Error loading item data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadItemData();
  }, [location.pathname, itemId]);

  if (isLoading) return <div className="loading-placeholder"><p>Procesando...</p></div>;
  if (!itemData) return <div className="loading-placeholder"><p>Procesando... Intente más tarde</p></div>;

  return (
    <div className="news">
      <div className="news-content">
        <h2>{itemData.title}</h2>
        <Badge className="date" bg="secondary">{itemData.date}</Badge>
        <hr />
        <div className="news-image">
          <img src={itemData.image || 'https://cdn-redmaestra-endpoint.azureedge.net/static/media/default.png'} alt={itemData.title} />
        </div>
        <ReactMarkdown>{itemData.body}</ReactMarkdown>
        {itemData.link && <Button href={itemData.link} target="_blank" className="btn btn-primary bl">Ingresa Aquí</Button>}
      </div>
      <button onClick={() => navigate(-1)} className="btn btn-primary bl">Volver</button>
    </div>
  );
}

export default NovItem;
