const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

/**
 * Fetch a JSON file from the storage account while preventing caching.
 * @param {string} jsonFile - The JSON file name (e.g., 'statisticsData.json').
 * @returns {Promise<Object>} - The parsed JSON data.
 */
export const getJsonFromStorage = async (jsonFile) => {
  try {
    const timestamp = new Date().getTime(); // Generate a unique timestamp
    const response = await fetch(`${CDN_BASE_URL}${jsonFile}?t=${timestamp}`, {
      cache: 'no-store', // Ensure the browser does not store it
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    if (!response.ok) throw new Error(`Failed to fetch ${jsonFile}`);
    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${jsonFile}:`, error);
    return null; // Return null to handle errors gracefully
  }
};
