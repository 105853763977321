import React from 'react';
import './footer.css';
import { getStaticUrl } from '../../../utils/getStaticUrl';

function Footer() {
  const facebookIcon = getStaticUrl('media-icons/logo-facebook.png');
  const instagramIcon = getStaticUrl('media-icons/logo-instagram.png');
  const linkedinIcon = getStaticUrl('media-icons/logo-linkedin.png');

  return (
    <footer className="footer" id="footer">
      <div className="footcontent">
        <ul>
          <li id="footleft">
            <p>Síguenos en nuestras<span> Redes Sociales</span></p>
            <div>
              <ul>
                <li>
                  <a href="https://www.linkedin.com/company/redmaestra/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinIcon} alt="LinkedIn" />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/redmaestracl/" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIcon} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/redmaestracl/" target="_blank" rel="noopener noreferrer">
                    <img src={facebookIcon} alt="Facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li id="footright">
            <p>Para cualquier otro requerimiento o consulta <span>¡escríbenos!</span></p>
            <p style={{ fontWeight: "bold" }}>
              <a href="mailto:contacto@redmaestra.cl" style={{ color: "white" }}>contacto@redmaestra.cl</a>
            </p>
          </li>
        </ul>
      </div>
      <div className="footlegal">
        <p>Todos los derechos reservados, 2025.</p>
      </div>
    </footer>
  );
}

export default Footer;
