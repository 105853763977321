import React from 'react';
import './subscribe.css';
import { getStaticUrl } from '../../../../../utils/getStaticUrl';

function Subscribe() {
 return (
     <div id="subscribes">
        <div id="leftbox">
            <ul>
                <li><img src={getStaticUrl('icons/icono-caja-herramientas-ama.png')} alt="BoxL"/></li>
                <li><p>¿Eres <span>mujer </span>y quieres sumarte?</p></li>
                <li><a href="https://redmaestra.azurewebsites.net/api/formularios/agregar_comunidades/"><button className="yellowbut">Inscríbete</button></a></li>
            </ul>
        </div>
        <div id="rightbox">
            <ul>
                <li><img src={getStaticUrl('icons/icono-ladrillos-mora.png')} alt="BoxR"/></li>
                <li><p>¿Eres <span>empresa </span>y quieres trabajar con nosotros?</p></li>
                <li><a href="https://redmaestra.azurewebsites.net/api/formularios/agregar_empresas/"><button className="purplebut">Contáctanos</button></a></li>
            </ul>
        </div>
    </div>
 );
}

export default Subscribe;