import React, { useState, useEffect } from 'react';
import './rrss.css';
import { getJsonFromStorage } from '../../../../../utils/getJsonFromStorage';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner

function Redes() {
  const [instagramData, setInstagramData] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchInstagramData = async () => {
      const jsonData = await getJsonFromStorage('instagramData.json');
      if (jsonData && jsonData.media && jsonData.media.data) {
        setInstagramData(jsonData);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    fetchInstagramData();
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
  };

  const handleCardClick = (post) => {
    setSelectedPost(post);
  };

  const handleClose = () => {
    setSelectedPost(null);
  };

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <Spinner animation="border" role="status">
        </Spinner>
      </div>;
  }

  if (error || !instagramData?.media?.data) {
    return <p>Error al cargar información de Instagram.</p>;
  }

  return (
    <>
      <h1>Novedades</h1>
      <div id="rrss_block">
        {instagramData.media.data.map((post, index) => {
          const cardClass = `card rrss rrss${(index % 4) + 1}`;

          return (
            <div key={index} className={cardClass} onClick={() => handleCardClick(post)}>
              <img
                src={post.thumbnail_url || post.media_url}
                className="card-img-top"
                alt={post.caption ? post.caption.slice(0, 100) + '...' : 'Instagram Post'}
              />
            </div>
          );
        })}
      </div>

      {selectedPost && (
        <div className="expanded-card-overlay" onClick={handleClose}>
          <div className="expanded-card-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedPost.thumbnail_url || selectedPost.media_url}
              alt={selectedPost.caption ? selectedPost.caption.slice(0, 30) + '...' : 'Instagram Post'}
              className="expanded-card-image"
            />
            <div className="expanded-card-body">
              <h5 className="card-title badge rounded-pill text-bg-secondary">
                {formatDate(selectedPost.timestamp)}
              </h5>
              <p className="card-rrss">{selectedPost.caption}</p>
              <a href={selectedPost.permalink} className="btn btn-primary" target="_blank" rel="noreferrer">
                Ver Post
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Redes;
