import React from 'react';
import Nosotra from '../components/sections/organizacion/nosotras/nosotras/nosotras';
import Objetivos from '../components/sections/organizacion/nosotras/objetivos/objetivos';
import Equipo from '../components/sections/organizacion/nosotras/equipo/equipo';
import Directorio from '../components/sections/organizacion/nosotras/directorio/directorio';

const componentMapping = {
  nosotras: Nosotra,
  objetivos: Objetivos,
  equipo: Equipo,
  directorio: Directorio
};

const Nosotras = () => {
  return (
    <>
    {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
    </>
  );
};

export default Nosotras;
