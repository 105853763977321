import React, { useState, useEffect } from 'react';
import './companies.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getStaticUrl } from '../../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../../utils/getJsonFromStorage';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner

function Companies() {
  const [partnerLogos, setPartnerLogos] = useState([]);
  const [otherLogos, setOtherLogos] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      const jsonData = await getJsonFromStorage('companies.json');
      if (jsonData && jsonData.partners && jsonData.others) {
        setPartnerLogos(jsonData.partners.map(getStaticUrl));
        setOtherLogos(jsonData.others.map(getStaticUrl));
      } else {
        setError(true);
      }
      setLoading(false);
    };

    fetchCompanies();
  }, []);

  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 4 } },
      { breakpoint: 520, settings: { slidesToShow: 3 } },
    ],
  };

  if (loading) return <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <Spinner animation="border" role="status">
        </Spinner>
      </div>;
  if (error) return <p>Error cargando compañias.</p>;

  return (
    <>
      <h1>Hemos trabajado con</h1>
      <div className="container">
        <Slider {...settings}>
          {partnerLogos.map((logo, index) => (
            <div className="slide" key={index}>
              <img src={logo} alt={`Partner Logo ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
      <button className="companies-but" onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Ocultar' : 'Ver más'}
      </button>
      {showMore && (
        <>
          <h2>También hemos trabajado con...</h2>
          <div className="container">
            <Slider {...settings}>
              {otherLogos.map((logo, index) => (
                <div className="slide" key={index}>
                  <img src={logo} alt={`Other Logo ${index}`} />
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
}

export default Companies;
