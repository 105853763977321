import React from 'react';
import MainBanner from '../components/sections/generales/inicio/lienzoprincipal/main_banner';
import Statistics from '../components/sections/generales/inicio/estadisticas/statistics';
import ProgBanner from '../components/sections/generales/inicio/lienzoprogramas/prog_banner';
import Companies from '../components/sections/generales/inicio/organizaciones/companies';
import Subscribe from '../components/sections/generales/inicio/suscripciones/subscribe';
import Redes from '../components/sections/generales/inicio/redessociales/rrss';
// import DonationPopup from '../components/1. Popup/donationPopup';

const componentMapping = {
  main_banner: MainBanner,
  statistics: Statistics,
  prog_banner: ProgBanner,
  companies: Companies,
  subscribe: Subscribe,
  rrss: Redes,
};

const HomePage = () => {
  return (
    <>
      {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
      {/* <DonationPopup /> */}
    </>
  );
};

export default HomePage;
