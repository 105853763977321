import React, { useState, useEffect } from 'react';
import './programas.css';
import { getStaticUrl } from '../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../utils/getJsonFromStorage';

function Programa() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [icons, setIcons] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const jsonData = await getJsonFromStorage('programas.json');
      if (jsonData && jsonData.icons && jsonData.programs) {
        setIcons(jsonData.icons.map(getStaticUrl));
        setPrograms(jsonData.programs.map(getStaticUrl));
      } else {
        setError(true);
      }
      setLoading(false);
    };

    fetchImages();
  }, []);

  if (loading) return <p>Cargando programas...</p>;
  if (error) return <p>Error al cargar programas.</p>;

  const handleImageClick = (src) => setSelectedImage(src);
  const handleClose = () => setSelectedImage(null);

  return (
    <>
      <div id="prog_showcase">
        <img src={icons[0]} alt="Maestra" />
        <div id="prog_title">
          <h2>¿Cómo son los programas RedMaestra?</h2>
          <p>
            Son trabajos colaborativos con organizaciones para potenciar las competencias de un grupo de mujeres a través de
            capacitaciones integrales en oficios de la construcción, convirtiéndolas en grandes maestras.
          </p>
        </div>
      </div>

      <h1>¿Para quienes están dirigidos?</h1>

      <div className="programas_block">
        <ul>
          <li>
            <img src={icons[1]} alt="Icono Academia" />
            <p>
              Organizaciones interesadas en <span>desarrollar mano de obra femenina en un sector productivo</span>
            </p>
          </li>
          <li>
            <img src={icons[2]} alt="Icono Caja" />
            <p>
              Organizaciones interesadas en <span>potenciar a sus colaboradoras o aumentar su dotación</span>
            </p>
          </li>
          <li>
            <img src={icons[3]} alt="Icono Redes" />
            <p>
              Organizaciones interesadas en <span>formar alianzas para alcanzar objetivos estratégicos</span>
            </p>
          </li>
        </ul>
      </div>

      <div id="prog">
        <h1>¿Qué rol cumple cada uno?</h1>
        <div id="prog_left">
          <div>
            <h2>1. Organizaciones</h2>
            <p>
              Son empresas, municipalidades o fundaciones que buscan generar impactos positivos en comunidades o grupos de interés
              a través del desarrollo profesional y personal de las mujeres.
            </p>
            <p>Son el principal ente financiador de los programas.</p>
            <p>Dentro de las motivaciones que han llevado a organizaciones a trabajar con nosotras se destacan:</p>
            <ul>
              <li>
                <p>
                  Interés por aumentar su Responsabilidad Social Empresaria o cumplir con uno o más de los Objetivos de Desarrollo
                  Sostenible
                </p>
              </li>
              <li>
                <p>
                  Necesidad de desarrollar mano de obra femenina específica calificada en el mercado laboral o alcanzar ciertos
                  estándares, certificaciones y/o reconocimientos
                </p>
              </li>
            </ul>
          </div>
          <img src={icons[5]} alt="Icono Figa" id="prog_figa" />
          <img src={icons[4]} alt="Icono Emp" id="prog_fig1" />
        </div>

        <div id="prog_right">
          <img src={icons[5]} alt="Icono Figb" id="prog_figb" />
          <img src={icons[6]} alt="Icono Cap" id="prog_fig2" />
          <div>
            <h2>2. RedMaestra</h2>
            <p>Somos el articulador que integra a todos los actores de un ecosistema necesarios para un propósito común.</p>
            <p>Dentro de nuestras fortalezas destacamos:</p>
            <ul>
              <li>
                <p>Amplia experiencia capacitando integramente a mujeres para convertirlas en maestras en oficios</p>
              </li>
              <li>
                <p>Aseguramos que los esfuerzos y trabajos colaborativos generen un impacto que logre transformar la vida de las participantes</p>
              </li>
              <li>
                <p>Trabajamos día a día para contar con una gran red de contactos y a su vez visibilizar los resultados a través de los medios de comunicación</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <h1>¿Cuál es el impacto generado?</h1>

      <div className="impacto">
        <img src={icons[7]} className="left-image" alt="LeftImp" />
        <div>
          <p>
            Chile hoy vive una cruda realidad: existe un sin fin de mujeres que quieren surgir y maestras en “tierra de nadie”, a lo
            largo de todo el país.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            A través de nuestros programas logramos generar en las participantes:
            <p>
              <br />
              <span>Autonomía Económica</span>
              <br />
              <span>Reconversión Laboral</span>
              <br />
              <span>Aumento de su Bienestar</span>
            </p>
          </p>
          <p>
            Desde RedMaestra, sostenemos con firmeza la creencia de que aún existen ideas y metodologías revolucionarias capaces de
            impulsar cambios concretos en beneficio de nuestra sociedad.
          </p>
        </div>
        <img src={icons[8]} className="right-image" alt="RightImp" />
      </div>

      <div className="blocks" id="prog_gallery">
        <ul>
          {programs.slice(0, 5).map((src, index) => (
            <li key={index}>
              <img src={src} alt={`P${index + 1}`} onClick={() => handleImageClick(src)} />
            </li>
          ))}
        </ul>
        <ul>
          {programs.slice(5).map((src, index) => (
            <li key={index}>
              <img src={src} alt={`P${index + 6}`} onClick={() => handleImageClick(src)} />
            </li>
          ))}
        </ul>
      </div>

      {selectedImage && (
        <div className="expanded-image-overlay" onClick={handleClose}>
          <img src={selectedImage} alt="Expanded View" className="expanded-image" onClick={(e) => e.stopPropagation()} />
        </div>
      )}
    </>
  );
}

export default Programa;
