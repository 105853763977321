import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import HomePage from './pages/HomePage';
import Nosotras from './pages/Nosotras'; 
import Programas from './pages/Programas'; 
import Convocatorias from './pages/Convocatorias';
import Novedades from './pages/Novedades';
import NovItem from './components/sections/novedades/novedades/novItem';
import LinkTree from './pages/LinkTree';
import NotFound from './pages/NotFound';  // Import the new NotFound component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><HomePage /></Layout>} />
        <Route path="/nosotras" element={<Layout><Nosotras /></Layout>} />
        <Route path="/programas" element={<Layout><Programas /></Layout>} />
        <Route path="/convocatorias" element={<Layout><Convocatorias /></Layout>} />
        <Route path="/noticias" element={<Layout><Novedades /></Layout>} />
        <Route path="/noticias/:itemId" element={<Layout><NovItem /></Layout>} />
        <Route path="/testimonios" element={<Layout><Novedades /></Layout>} />
        <Route path="/testimonios/:itemId" element={<Layout><NovItem /></Layout>} />
        <Route path="/linktree" element={<LinkTree />} />

        {/* Catch-all route for undefined URLs */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
