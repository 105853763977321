import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './linktree.css';
import { getStaticUrl } from '../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../utils/getJsonFromStorage';

function Linktree() {
  const [lastTwoNoticias, setLastTwoNoticias] = useState([]);
  const [lastTestimonio, setLastTestimonio] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadNoticiasTestimonios = async () => {
      const sortByDate = (data) => data.slice().sort((a, b) => new Date(b.date) - new Date(a.date));

      const noticiasData = await getJsonFromStorage('noticias.json');
      if (noticiasData) {
        setLastTwoNoticias(sortByDate(noticiasData).slice(0, 2));
      } else {
        setError(true);
      }

      const testimoniosData = await getJsonFromStorage('testimonios.json');
      if (testimoniosData && testimoniosData.length > 0) {
        setLastTestimonio(sortByDate(testimoniosData)[0]);
      } else {
        setError(true);
      }
    };

    loadNoticiasTestimonios();
  }, []);

  if (error) {
    return <div>Error al cargar contenido. Por favor intente de nuevo más tarde.</div>;
  }

  return (
    <>
      <Link to="/" className="lt-logo">
        <img src={getStaticUrl('logo-blue-bg.png')} alt="Logo" loading="eager" />
      </Link>

      {lastTwoNoticias.map((noticia) => (
        <a key={noticia.id} href={`/noticias/${noticia.id}`} className="lt-a">
          <img src={noticia.image} className="lt-img" alt={noticia.title} />
          <h2 className="lt-h">{noticia.title}</h2>
        </a>
      ))}

      {lastTestimonio && (
        <a href={`/testimonios/${lastTestimonio.id}`} className="lt-a">
          <img src={lastTestimonio.image} className="lt-img lt-test" alt={lastTestimonio.title} />
          <h2 className="lt-h">{lastTestimonio.title}</h2>
        </a>
      )}

      <a href="https://www.youtube.com/watch?v=ynpBsqyeXH4&ab_channel=RedMaestra" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={getStaticUrl('media-icons/logo-youtube.png')} className="lt-img lt-ico" alt="YouTube" />
        <h2 className="lt-h">¿Qué es RedMaestra?</h2>
      </a>

      <a href="https://instagram.com/redmaestracl/" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={getStaticUrl('media-icons/logo-instagram.png')} className="lt-img lt-ico" alt="Instagram" />
        <h2 className="lt-h">Instagram</h2>
      </a>

      <a href="https://www.facebook.com/redmaestracl/" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={getStaticUrl('media-icons/logo-facebook.png')} className="lt-img lt-ico" alt="Facebook" />
        <h2 className="lt-h">Facebook</h2>
      </a>

      <a href="https://www.linkedin.com/company/redmaestra/" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={getStaticUrl('media-icons/logo-linkedin.png')} className="lt-img lt-ico" alt="LinkedIn" />
        <h2 className="lt-h">LinkedIn</h2>
      </a>

      <a href="mailto:contacto@redmaestra.cl" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={getStaticUrl('icons/logo-correo.png')} className="lt-img lt-ico" alt="Correo" />
        <h2 className="lt-h">Correo</h2>
      </a>

      <a href="/" className="lt-a">
        <img src={getStaticUrl('icons/maestra2.png')} className="lt-img lt-ico" alt="Sitio Web" />
        <h2 className="lt-h">Sitio Web</h2>
      </a>
    </>
  );
}

export default Linktree;
