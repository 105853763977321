import React from 'react';
import Novedad from '../components/sections/novedades/novedades/novedades';

const Novedades = () => {
  return (
    <>

          <Novedad />

    </>
  );
};

export default Novedades;
