import React, { useState, useEffect } from 'react';
import './equipo.css';
import { getStaticUrl } from '../../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../../utils/getJsonFromStorage';

function Equipo() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const jsonData = await getJsonFromStorage('equipo.json');
      if (jsonData && jsonData.images) {
        setImages(jsonData.images.map(getStaticUrl));
      } else {
        setError(true);
      }
      setLoading(false);
    };

    fetchImages();
  }, []);

  if (loading) return <p>Cargando equipo...</p>;
  if (error) return <p>Error al cargar equipo.</p>;

  // Function to split array into chunks
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const groupedImages = chunkArray(images, 3);

  return (
    <>
      <h1>Nuestro Equipo</h1>
      <div id="nos_team">
        {groupedImages.map((group, index) => (
          <ul key={index}>
            {group.map((image, idx) => (
              <li key={idx}>
                <img src={image} alt={`Team Member ${index * 3 + idx + 1}`} />
              </li>
            ))}
          </ul>
        ))}
      </div>
    </>
  );
}

export default Equipo;
