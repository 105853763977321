import staticMap from '../static/staticMap.json';

const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

/**
 * Get the URL for a static asset dynamically based on the environment.
 * @param {string} assetPath - The path of the asset relative to the static folder.
 * @returns {string} - The URL for the asset.
 */
export const getStaticUrl = (assetPath) => {
  const isProduction = process.env.NODE_ENV === 'production';

  if (isProduction) {
    // Return CDN URL in production
    return `${CDN_BASE_URL}${staticMap[assetPath]}`;
  } else {
    // Return local URL in development
    return require(`../static/${assetPath}`);
  }
};