import React, { useState, useEffect } from 'react';
import './directorio.css';
import { getStaticUrl } from '../../../../../utils/getStaticUrl';
import { getJsonFromStorage } from '../../../../../utils/getJsonFromStorage';

function Directorio() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const jsonData = await getJsonFromStorage('directorio.json');
      if (jsonData && jsonData.images) {
        setImages(jsonData.images.map(getStaticUrl));
      } else {
        setError(true);
      }
      setLoading(false);
    };

    fetchImages();
  }, []);

  if (loading) return <p>Cargando directorio...</p>;
  if (error) return <p>Error al cargar directorio.</p>;

  return (
    <>
      <h1>Nuestro Directorio</h1>
      <div id="nos_dic">
        <ul>
          {images.map((image, index) => (
            <li key={index}>
              <img src={image} alt={`Director Member ${index + 1}`} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Directorio;
